import * as React from "react"

export default function NotFoundPage({ pageContext }) {
  const { i18n } = pageContext

  return (
    <div className="w-full mt-12 out-wrapper">
      <h1 className="text-4xl">{i18n[404].h1}</h1>
      <p className="text-xl">{i18n["404"].p}</p>
    </div>
  )
}
